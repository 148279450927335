
import rules from "@/mixins/rules"

export default {
    name: "NumberInput",
    mixins: [rules],
    props: {
        /** lower value for validation */
        lowerValue: {
            type: Number,
            default: null,
        },
        /** upper value for validation */
        upperValue: {
            type: Number,
            default: null,
        },
        /** max width of the input */
        maxWidth: {
            type: Number,
            default: 90,
            validator: (val) => val >= 0,
        },
        /** sets up max length number length (e.g. 4 => 9999) */
        inputLength: {
            type: Number,
            default: 4,
            validator: (val) => val > 0,
        },
        /** Size of step with step buttons */
        stepButton: {
            type: Number,
            default: 50,
            validator: (val) => val > 0,
        },
        /** Size of step with mousewheel */
        stepWheel: {
            type: Number,
            default: 10,
            validator: (val) => val > 0,
        },
        /** for v-model */
        value: {
            type: Number,
            default: null,
            validator: (val) => val >= 0,
        },
        /** placeholder number */
        placeholder: {
            type: Number,
            default: 0,
            validator: (val) => val >= 0,
        },
        /** change the v-model bound to the component on every inputted character */
        emitOnInput: {
            type: Boolean,
            default: false,
        },
        dense: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            allowScrollingInput: false,
            internalValue: this.value, // accessed only through model
        }
    },
    computed: {
        model: {
            get() {
                return this.internalValue
            },
            set(value) {
                this.internalValue = Math.min(
                    Math.max(0, value),
                    this.maxInputValue
                )
            },
        },
        validator() {
            if (this.lowerValue != null && this.upperValue != null)
                return [this.ruleBetween(this.lowerValue, this.upperValue)]
            else if (this.lowerValue != null)
                return [this.ruleMin(this.lowerValue)]
            else if (this.upperValue != null)
                return [this.ruleMax(this.upperValue)]
            return []
        },
        wrapperStyles() {
            return {
                "max-width": `${this.maxWidth}px`,
            }
        },
        maxInputValue() {
            return parseInt("9".repeat(this.inputLength))
        },
    },
    watch: {
        value(val) {
            this.model = val
        },
    },
    methods: {
        async onKeyDown(e) {
            if (this.model === this.maxInputValue && e.key !== "Backspace")
                e.preventDefault()
            if (e.key === "Enter" || this.emitOnInput)
                setTimeout(this.emitNewValue, 100)
        },
        scrollHandler(e) {
            if (!this.allowScrollingInput) return
            e.preventDefault() // prevent page scrolling
            let newVal = this.model
            if (!newVal) newVal = this.placeholder
            newVal = newVal + (e.deltaY < 0 ? this.stepWheel : -this.stepWheel)
            this.model = newVal
        },
        onFocus() {
            this.allowScrollingInput = true
        },
        onBlur() {
            this.allowScrollingInput = false
            this.emitNewValue()
        },
        emitNewValue() {
            // Prevent redundant reloads of the search
            if (!this.model && !this.value) return
            if (this.model === this.value) return

            this.$emit("input", this.model)
        },
    },
}
