
import Button from "@/components/base/Button"
import { mdiThumbsUpDown, mdiThumbsUpDownOutline, mdiClose } from "@mdi/js"
import { mapGetters } from "vuex"
import { OABALYTICS_RATING_CARD } from "@/enums/oabalytics"

export default {
    name: "JobCardRating",
    components: {
        SpriteRatingEmoji: () =>
            import("@/components/base/image/SpriteRatingEmoji"),
        EmojiRatingSlider: () =>
            import("@/components/base/input/EmojiRatingSlider"),
        Button,
    },
    icons: {
        mdiClose,
        mdiThumbsUpDown,
        mdiThumbsUpDownOutline,
    },
    props: {
        job: {
            type: Object,
            required: true,
        },
    },
    data: () => ({
        showRatingMenu: false,
        animated: true,
    }),
    computed: {
        ...mapGetters("rating", ["getRating"]),
        isRated() {
            return this.getRating(this.job.id) !== undefined
        },
        isLiked() {
            return this.getRating(this.job.id) > 50 && this.$auth.loggedIn
        },
        currentIndex() {
            const EMOJI_COUNT = 10
            return Math.min(
                Math.floor(this.getRating(this.job.id) / EMOJI_COUNT),
                EMOJI_COUNT - 1
            )
        },
    },
    watch: {
        showRatingMenu(val) {
            /* Avoids animation on open */
            this.animated = !this.isLiked

            this.$oabalytics.trackEvent(OABALYTICS_RATING_CARD, {
                action: val ? "open" : "close",
                job_id: this.job.id,
            })
        },
    },
    methods: {
        onRatingClickOutside() {
            if (!this.showRatingMenu) return
            this.showRatingMenu = false
        },
    },
}
