import {
    APPRENTICESHIP_DUAL,
    APPRENTICESHIP_GRAD,
} from "@/enums/apprenticeshipTypes"

function getApprenticeshipTypeName(apprenticeship) {
    switch (apprenticeship?.type_id) {
        case APPRENTICESHIP_DUAL:
            return "Duales Studium"
        case APPRENTICESHIP_GRAD:
            return "Abiturientenprogramm"
        // Backward compatibility
        default:
            return "Ausbildung"
    }
}

export { getApprenticeshipTypeName }
