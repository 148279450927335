
import ProgressLinear from "@/components/base/ProgressLinear"
import ResponsiveVimeo from "@/components/base/video/ResponsiveVimeo"

export default {
    name: "UploadVideoPreview",
    components: {
        ResponsiveVimeo,
        ProgressLinear,
    },
    props: {
        video: {
            type: Object,
            default: null,
        },
    },
    computed: {
        request() {
            return this.video?.request
        },
        isPending() {
            if (!this.request) return false
            return this.video.request.state === "pending"
        },
        isIndeterminate() {
            const progress = this.request?.progress
            if (!progress) return true
            return progress === 0 || progress === 100
        },
        preflight() {
            if (!this.request) return false
            return (
                typeof this.request.cancel !== "function" &&
                this.request.state === "pending"
            )
        },
        error() {
            if (this.request?.state === "error") {
                if (this.request.error.message === "Network Error") {
                    return "Keine Internetverbindung"
                }
                return this.request.error
            }
            return false
        },
    },
}
