export function updateRouteParams(ctx, params, exclude = []) {
    const newQuery = { ...ctx.$route.query }

    // Update or delete parameters based on the input params object
    for (const key in params) {
        const value = params[key]
        if (value === undefined || value === false) {
            delete newQuery[key]
        } else if (value === true) {
            newQuery[key] = null
        } else if (!exclude.includes(key)) {
            newQuery[key] = value
        }
    }

    // Update the route without refreshing the page
    ctx.$router.push({ query: newQuery })
}
