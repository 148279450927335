import {
    routeToBusiness,
    routeToJob,
    routeToJobFairIndex,
    routeToPost,
    routeToCitySearch,
    routeToCityApprenticeships,
    routeToCityTraineeships,
    routeToCityNews,
    routeToCityBusinesses,
    routeToListCard,
    routeToInternshipLandingpage,
} from "@/helpers/routing"
import { getApprenticeshipTypeName } from "@/helpers/job"

export default {
    methods: {
        getBreadcrumb(items) {
            const breadcrumb = {
                "@context": "http://schema.org",
                "@type": "BreadcrumbList",
                itemListElement: [],
            }
            let position = 1
            let self = this
            items.forEach(function (item) {
                if (item.identifier === "business") {
                    breadcrumb.itemListElement.push(
                        self.getBusinessBreadcrumbListElement(
                            item.business,
                            position
                        )
                    )
                } else if (item.identifier === "job") {
                    breadcrumb.itemListElement.push(
                        self.getJobBreadcrumbListElement(item.job, position)
                    )
                } else if (item.identifier === "internship") {
                    breadcrumb.itemListElement.push(
                        self.getInternshipBreadcrumbListElement(
                            item.job,
                            position
                        )
                    )
                } else if (item.identifier === "geo_city") {
                    breadcrumb.itemListElement.push(
                        self.getGeoCityBreadcrumbListElement(
                            item.location,
                            position
                        )
                    )
                } else if (item.identifier === "geo_city_apprenticeships") {
                    breadcrumb.itemListElement.push(
                        self.getGeoCityApprenticeshipsBreadcrumbListElement(
                            item.location,
                            position
                        )
                    )
                } else if (
                    item.identifier === "geo_city_apprenticeships_category"
                ) {
                    breadcrumb.itemListElement.push(
                        self.getGeoCityApprenticeshipCategoryBreadcrumbListElement(
                            item.location,
                            item.category,
                            position
                        )
                    )
                } else if (item.identifier === "geo_city_traineeships") {
                    breadcrumb.itemListElement.push(
                        self.getGeoCityTraineeshipsBreadcrumbListElement(
                            item.location,
                            position
                        )
                    )
                } else if (item.identifier === "geo_city_news") {
                    breadcrumb.itemListElement.push(
                        self.getGeoCityNewsBreadcrumbListElement(
                            item.location,
                            position
                        )
                    )
                } else if (item.identifier === "jobfair") {
                    breadcrumb.itemListElement.push(
                        self.getJobFairBreadcrumbListElement(
                            item.location,
                            position
                        )
                    )
                } else if (item.identifier === "geo_city_businesses") {
                    breadcrumb.itemListElement.push(
                        self.getGeoCityBusinessesBreadcrumbListElement(
                            item.location,
                            position
                        )
                    )
                } else if (item.identifier === "post") {
                    breadcrumb.itemListElement.push(
                        self.getPostBreadcrumbListElement(item.post, position)
                    )
                } else if (item.identifier === "ListCard") {
                    breadcrumb.itemListElement.push(
                        self.getListCardBreadcrumbListElement(
                            item.listName,
                            item.listSlug,
                            item.location,
                            position
                        )
                    )
                }
                position++
            })
            return breadcrumb
        },
        getBusinessBreadcrumbListElement(business, position) {
            return {
                "@type": "ListItem",
                position: position,
                name: business.name,
                item: this.getAbsoluteURL(routeToBusiness(business)),
            }
        },
        getBreacrumbJobName(job) {
            var jobName
            if (job.type_id === 1) {
                const apprenticeshipName = getApprenticeshipTypeName(
                    job.apprenticeship
                )
                jobName = `${apprenticeshipName} ${job.name}`
                if (job.traineeship_data?.is_active) {
                    jobName = `${apprenticeshipName} / Praktikum ${job.name}`
                }
            } else if (job.type_id === 2) {
                jobName = "Praktikum " + job.name
            } else if (job.type_id === 3) {
                jobName = job.name
            }
            return jobName
        },
        getJobBreadcrumbListElement(job, position) {
            return {
                "@type": "ListItem",
                position: position,
                name: this.getBreacrumbJobName(job),
                item: this.getAbsoluteURL(routeToJob(job)),
            }
        },
        getInternshipBreadcrumbListElement(job, position) {
            return {
                "@type": "ListItem",
                position: position,
                name: "Praktikum",
                item: this.getAbsoluteURL(routeToInternshipLandingpage(job)),
            }
        },
        getJobFairBreadcrumbListElement(location, position) {
            return {
                "@type": "ListItem",
                position: position,
                name: location.name,
                item: this.getAbsoluteURL(routeToJobFairIndex(location.slug)),
            }
        },
        getPostBreadcrumbListElement(post, position) {
            return {
                "@type": "ListItem",
                position: position,
                name: post.title,
                item: this.getAbsoluteURL(routeToPost(post)),
            }
        },
        getGeoCityBreadcrumbListElement(location, position) {
            return {
                "@type": "ListItem",
                position: position,
                name: "Ausbildung, Praktikum & Arbeitgeber " + location?.name,
                item: this.getAbsoluteURL(routeToCitySearch(location?.slug)),
            }
        },
        getGeoCityApprenticeshipsBreadcrumbListElement(location, position) {
            if (this.$route.query.praktikum !== undefined) {
                return {
                    "@type": "ListItem",
                    position: position,
                    name: "Praktikum & Schülerpraktikum " + location.name,
                    item: this.getAbsoluteURL(
                        routeToCityTraineeships(location.slug)
                    ),
                }
            }
            return {
                "@type": "ListItem",
                position: position,
                name: "Ausbildung & Lehrstellen " + location.name,
                item: this.getAbsoluteURL(
                    routeToCityApprenticeships(location.slug)
                ),
            }
        },
        getGeoCityApprenticeshipCategoryBreadcrumbListElement(
            location,
            category,
            position
        ) {
            return {
                "@type": "ListItem",
                position: position,
                name: "Kategorie: " + category.name,
                item: this.getAbsoluteURL(
                    routeToCityApprenticeships(
                        location.slug,
                        category.identifier
                    )
                ),
            }
        },
        getGeoCityTraineeshipsBreadcrumbListElement(location, position) {
            return {
                "@type": "ListItem",
                position: position,
                name: "Schülerpraktikum " + location.name,
                item: this.getAbsoluteURL(
                    routeToCityTraineeships(location.slug)
                ),
            }
        },
        getGeoCityNewsBreadcrumbListElement(location, position) {
            return {
                "@type": "ListItem",
                position: position,
                name: "Berufsorientierungsnews " + location.name,
                item: this.getAbsoluteURL(routeToCityNews(location.slug)),
            }
        },
        getGeoCityBusinessesBreadcrumbListElement(location, position) {
            return {
                "@type": "ListItem",
                position: position,
                name: "Arbeitgeber & Unternehmen " + location.name,
                item: this.getAbsoluteURL(routeToCityBusinesses(location.slug)),
            }
        },
        getListCardBreadcrumbListElement(
            listName,
            listSlug,
            location,
            position
        ) {
            return {
                "@type": "ListItem",
                position: position,
                name: listName,
                item: this.getAbsoluteURL(routeToListCard(location, listSlug)),
            }
        },

        getAbsoluteURL(routeObject) {
            return (
                this.$config.baseURL +
                this.$router.resolve(routeObject).route.fullPath
            )
        },
    },
}
