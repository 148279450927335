
import OPicture from "@/components/base/image/OPicture"
import ProgressLinear from "@/components/base/ProgressLinear"

export default {
    name: "UploadPreview",
    components: { OPicture, ProgressLinear },
    props: {
        image: {
            type: Object,
            required: true,
        },
        fit: {
            default: "cover",
            type: String,
            validator: (val) =>
                ["fill", "contain", "cover", "none", "scale-down"].includes(
                    val
                ),
        },
    },
    computed: {
        request() {
            return this.image.request
        },
        isPending() {
            if (!this.request) return false
            return this.request.state === "pending"
        },
        isIndeterminate() {
            const progress = this.request.progress
            return progress === 0 || progress === 100
        },
        error() {
            if (this.request?.state === "error") {
                const message = this.request.error.message
                if (message === "Network Error") {
                    return "Keine Internetverbindung"
                }
                if (message === "Request failed with status code 500") {
                    return "Bild zu groß"
                }
                return message
            }
            return false
        },
    },
}
