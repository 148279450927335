
export default {
    name: "BoxCheckbox",
    model: {
        prop: "value",
        event: "select",
    },
    props: {
        value: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        hoverEffect: {
            type: Boolean,
            default: true,
        },
        activeEffect: {
            type: Boolean,
            default: true,
        },
    },
    methods: {
        onClick() {
            if (!this.disabled) {
                this.$emit("select", !this.value)
            }
        },
    },
}
