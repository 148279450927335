
import { Search, getHead } from "@/models/search"
import { mdiChevronDown, mdiFilter } from "@mdi/js"
import { filters, location, icon_channel } from "@/assets/icons/js"

import { updateRouteParams } from "@/helpers/router"
import SearchHeader from "@/components/search/v2/header/SearchHeader"
import breadcrumb from "@/mixins/breadcrumb"
import Button from "@/components/base/Button"
import OPicture from "@/components/base/image/OPicture.vue"

export default {
    name: "SearchPage",
    components: {
        OPicture,
        SearchHeader,
        Button,
        SearchNoResults: () => import("@/components/search/v2/SearchNoResults"),
        SearchResult: () => import("@/components/search/v2/SearchResult"),
        Modal: () => import("@/components/base/modal/Modal"),
        LocationSearch2: () => import("@/components/search/LocationSearch2"),
        SearchLocationSuggestions: () =>
            import("@/components/search/v2/SearchLocationSuggestions"),
        SearchAdminButton: () =>
            import("@/components/search/v2/SearchAdminButton"),
    },
    mixins: [breadcrumb],
    props: {
        /**
         * search object
         */
        search: {
            type: Search,
            required: true,
            validator: () => true,
        },
        customItems: {
            type: Array,
            default: () => [],
        },
    },
    data: () => ({
        showLocationModal: false,
    }),
    icons: {
        mdiChevronDown,
        mdiFilter,
        location,
        filters,
        icon_channel,
    },
    computed: {
        isTraineeshipSearch() {
            return this.search.hasTraineeship
        },
        isSearchExecuted() {
            return this.search ? !!this.search.meta : false
        },
        totalResults() {
            return this.isSearchExecuted ? this.search.meta.total : null
        },
        headline() {
            const count = this.isSearchExecuted ? this.totalResults : ""
            let type = count === 1 ? "Ausbildungsplatz" : "Ausbildungsplätze"
            if (this.isTraineeshipSearch) type += " mit Praktikum"
            return `${count} ${type}`
        },
        searchArea() {
            return this.search?.location ?? this.search?.channel
        },
        searchAreaName() {
            if (!this.searchArea) return ""
            if (this.searchArea.name !== "Umgebung") return this.searchArea.name
            return "der Umgebung"
        },
        isChannel() {
            return !this.search?.location && !!this.search?.channel
        },
        channelLogo() {
            return this.search?.channel?.logo
        },
        searchAreaTitle() {
            if (this.isChannel) return this.searchArea.name
            return "in " + this.searchArea?.name
        },
    },
    methods: {
        async onSearchChange() {
            const newRoute = this.search.getRoute()
            if (
                newRoute.name !== this.$route.name ||
                newRoute.params?.locationSlug !==
                    this.$route.params?.locationSlug
            ) {
                await this.$router.push(newRoute)
            }
            updateRouteParams(this, newRoute.query, ["latitude", "longitude"])
            window.scrollTo(0, 0)
            await this.search.execute(true)
        },
        onLocationChange(location) {
            this.$oabalytics.trackEvent("search-location-input", {
                location,
            })
            this.showLocationModal = false
            this.search.setLocation(location)
            this.onSearchChange()
        },
        onLocationClick() {
            this.showLocationModal = true
            this.$oabalytics.trackEvent("search-location-modal-open")
        },
        resetSearch() {
            this.$refs.searchHeader.reset()
        },
    },
    head() {
        const head = getHead.call(this, this.search)
        if (!this.searchArea) return head
        head["__dangerouslyDisableSanitizers"] = ["script"]
        head.script.push({
            innerHTML: JSON.stringify(
                this.getBreadcrumb([
                    {
                        identifier: "geo_city_apprenticeships",
                        location: this.searchArea,
                    },
                ])
            ),
            type: "application/ld+json",
        })
        return head
    },
}
