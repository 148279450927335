
import { mdiMagnify, mdiClose, mdiChevronLeft } from "@mdi/js"

export default {
    name: "SearchTextField",
    emits: ["close", "open", "clear"],
    props: {
        /** True --> displays 'close' btn, false --> displays 'open' btn */
        isOpen: {
            type: Boolean,
            default: false,
        },
    },
    icons: {
        mdiMagnify,
        mdiChevronLeft,
        mdiClose,
    },
}
