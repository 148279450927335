
export default {
    name: "Dropdown",
    props: {
        minWidth: {
            type: Number,
            default: 350,
            validator: (val) => val >= 0,
        },
        fullscreen: {
            type: String,
            default: "none",
            validator: (val) =>
                [
                    "none",
                    "all",
                    "xsOnly",
                    "smOnly",
                    "smAndDown",
                    "smAndUp",
                    "mdOnly",
                    "mdAndDown",
                    "mdAndUp",
                    "lgOnly",
                    "lgAndDown",
                    "lgAndUp",
                    "xlOnly",
                ].includes(val),
        },
        shift: {
            type: Boolean,
            default: false,
        },
        attach: {
            type: [String, Boolean],
            default: false,
        },
        menuClass: {
            type: String,
            default: "",
        },
    },
    computed: {
        isFullScreen() {
            if (this.fullscreen === "none") return false
            else if (this.fullscreen === "all") return true
            return this.$breakpoint[this.fullscreen]
        },
        contentClasses() {
            return `rounded-2 white ${this.menuClass}`
        },
    },
}
