
import multiEventListener from "@/mixins/multiEventListener"
import { routeToBusiness, routeToBusinessSite } from "@/helpers/routing"
import {
    mdiMagnify,
    mdiChevronRight,
    mdiDelete,
    mdiClose,
    mdiChevronLeft,
} from "@mdi/js"
import { Search } from "@/models/search"
import { arrow_undo } from "@/assets/icons/js"
import SearchTextField from "@/components/search/v2/header/SearchTextField"

export default {
    name: "SearchDropdown",
    components: {
        SearchTextField,
        Tag: () => import("@/components/base/Tag"),
        BusinessLogo: () => import("@/components/business/BusinessLogo"),
    },
    /**
     * Input: Emits the dropdown state
     */
    emits: ["input"],
    mixins: [multiEventListener],
    props: {
        /** state of the search dropdown menu */
        value: {
            type: Boolean,
            default: false,
        },
        /** search object */
        search: {
            type: Search,
            required: true,
            validator: () => true,
        },
        /** type of search */
        type: {
            type: String,
            default: "Ausbildung",
        },
        /** tags to display */
        tags: {
            type: Array,
            default: () => [],
        },
        /** open the dropdown if the search query is empty */
        initiallyOpen: {
            type: Boolean,
            default: false,
        },
    },
    fetch() {
        this.setSearchQuery(this.search)
    },
    data: () => ({
        loading: false,
        icons: {
            arrow_undo,
            mdiMagnify,
            mdiDelete,
            mdiChevronRight,
            mdiChevronLeft,
            mdiClose,
        },
        currentFocusIndex: null,
        searchQuery: "",
        searchInputRef: "searchInput",
        searchSuggestions: [],
        searchInputWidth: 500,
        searchSuggestionsMeta: null,
    }),
    computed: {
        searchMenuModel: {
            get() {
                return this.value
            },
            set(value) {
                this.$emit("input", value)
            },
        },
        isSearchEmpty() {
            return this.searchQuery ? this.searchQuery.length === 0 : true
        },
        numberOfSearchQueryResults() {
            if (this.searchSuggestionsMeta)
                return this.searchSuggestionsMeta.total
            return 0
        },
        showSearchMenuContent() {
            return (
                this.searchSuggestions.length > 0 ||
                this.numberOfSearchQueryResults > 0 ||
                this.showTags
            )
        },
        showTags() {
            return this.tags.length > 0 && this.isSearchEmpty
        },
        businessSuggestions() {
            return this.searchSuggestions
                .filter((item) => item.type === "business")
                .slice(0, 3)
        },
        termSuggestions() {
            return this.searchSuggestions
                .filter((item) => item.type === "term")
                .slice(0, 3)
        },
        jobSuggestions() {
            return this.searchSuggestions
                .filter((item) => item.type === "job")
                .slice(0, 3)
        },
        menuContentStyles() {
            return {
                maxWidth: `${this.searchInputWidth}px`,
            }
        },
    },
    watch: {
        search: {
            deep: true,
            handler: function (value) {
                this.setSearchQuery(value)
                if (!value.term) this.resetSuggestions()
            },
        },
        searchMenuModel(newVal) {
            if (newVal) {
                this.$nextTick(() => {
                    this.setHoverListeners()
                })
            }
        },
    },
    async mounted() {
        await this.$nextTick()
        if (this.isSearchEmpty && this.initiallyOpen) this.openSearchMenu()
    },
    methods: {
        openSearchMenu() {
            this.$emit("search-menu-open")
            setTimeout(() => {
                this.$oabalytics.trackEvent("search-dropdown-open")
                this.searchMenuModel = true
            }, 200)
        },
        closeSearchMenu() {
            this.$emit("search-menu-close")
            this.searchMenuModel = false
        },
        setSearchQuery(searchObj) {
            let query = searchObj ? searchObj.term : ""
            if (query) {
                query = query.trim()
                this.searchQuery = query
                this.executeSuggest(query)
            } else {
                this.searchQuery = ""
            }
        },
        suggestion2query(value) {
            let string = ""
            if (value.type)
                switch (value.type) {
                    case "term":
                        string = value.item
                        break
                    case "business":
                        string =
                            value.item.name +
                            " (" +
                            value.item.address.city +
                            ")"
                        break
                    case "job":
                        string = value.item
                        break
                    default:
                        string = value.item
                }
            else string = value
            return string
        },
        getSuggestionItem(index) {
            if (index < this.termSuggestions.length)
                return this.termSuggestions[index]
            else if (
                index <
                this.jobSuggestions.length + this.termSuggestions.length
            )
                return this.jobSuggestions[index - this.termSuggestions.length]
            else if (
                index <
                this.businessSuggestions.length +
                    this.jobSuggestions.length +
                    this.termSuggestions.length
            )
                return this.businessSuggestions[
                    index -
                        this.jobSuggestions.length -
                        this.termSuggestions.length
                ]
            else return null
        },
        highlight(string) {
            if (!this.searchQuery) return
            const sq = this.searchQuery.replace(
                /[-[\]{}()*+?.,\\^$|#\s]/g,
                "\\$&"
            )
            if (this.searchQuery.length > 3) {
                const re = new RegExp(sq, "ig")
                return `<strong>${string.replace(
                    re,
                    "</strong>$&<strong>"
                )}</strong>`
            } else {
                const re = new RegExp(sq, "ig")
                return string.replace(re, "<strong>$&</strong>")
            }
        },
        async onInput(query) {
            // trim modifier to remove whitespaces
            query = query.trim()
            this.openSearchMenu()
            this.$oabalytics.trackEvent("search-input", {
                query,
            })
            await this.executeSuggest(query)
        },
        async executeSuggest(query) {
            if (!query) {
                this.resetSuggestions()
                return
            }
            try {
                const { data, meta } = await this.search.executeSuggestions(
                    query
                )
                // Late response handling (when searchQuery is empty)
                // not checking the local variable “query” here, because it might have changed since the request was sent
                if (this.isSearchEmpty) this.resetSuggestions()
                else this.setSuggestions(data, meta)
            } catch (err) {
                console.warn(err)
            }
        },
        setSuggestions(data, meta) {
            this.searchSuggestionsMeta = meta
            if (Array.isArray(data))
                this.searchSuggestions = data.sort((a, b) => a.score - b.score)
            this.$nextTick(() => {
                this.setHoverListeners()
            })
        },
        resetSuggestions() {
            this.searchSuggestions = []
            this.searchSuggestionsMeta = null
        },
        pushBusinessPage(businessSite) {
            if (businessSite.is_site_management_enabled) {
                this.$router.push(routeToBusinessSite(businessSite))
            } else {
                this.$router.push(routeToBusiness(businessSite))
            }
        },
        onSuggestionClick(item, index) {
            if (!item) return
            const query = this.suggestion2query(item)
            this.searchQuery = query
            this.$oabalytics.trackEvent("search-suggest-click", {
                item,
                index,
            })
            if (item.type === "business") this.pushBusinessPage(item.item)
            else this.executeSearch(query)
            this.closeSearchMenu()
        },
        clearSearchQuery() {
            this.searchQuery = ""
            this.$oabalytics.trackEvent("search-input-clear")
            setTimeout(() => {
                this.searchMenuModel = true
                this.resetSuggestions()
            }, 300)
        },
        executeSearch(query) {
            this.currentFocusIndex = null
            this.$emit("search", query)
        },
        onEnterKey() {
            if (this.currentFocusIndex !== null) {
                this.onSuggestionClick(
                    this.getSuggestionItem(this.currentFocusIndex),
                    this.currentFocusIndex
                )
                return
            }
            this.$oabalytics.trackEvent("search-key-enter", {
                query: this.searchQuery,
            })

            // check for duplicated search query
            const previousSearchTerm = this.search ? this.search.term : null
            const isPreviousSearchTermEmpty = previousSearchTerm
                ? previousSearchTerm.length === 0
                : true
            const bothTermsEmpty =
                this.isSearchEmpty && isPreviousSearchTermEmpty
            const hasTermNotChanged = previousSearchTerm === this.searchQuery
            if (bothTermsEmpty || hasTermNotChanged) return

            if (this.searchQuery) this.executeSearch(this.searchQuery)
            else {
                this.clearSearchQuery()
                if (this.search.term !== null) this.executeSearch(null)
            }
            this.closeSearchMenu()
        },
        // Show all suggestions on blur with empty query
        onBlur() {
            // check for duplicated search query
            const previousSearchTerm = this.search ? this.search.term : null
            const hasTermNotChanged = previousSearchTerm === this.searchQuery
            // previousSearchTerm == null handles both null and undefined
            if (
                !this.isSearchEmpty ||
                hasTermNotChanged ||
                previousSearchTerm == null
            )
                return
            this.executeSearch(null)
        },
        setHoverListeners() {
            if (!process.client) {
                return
            }
            const resultItemElems = [
                ...document.getElementsByClassName(
                    "search-dropdown__result-item"
                ),
            ]
            // Since resultItemElems DOM are removed, event listeners are removed as well
            this.addEventListeners(resultItemElems, ["mouseenter"], () => {
                this.currentFocusIndex = null
            })
        },
        onKeyDown(e) {
            if (e.key === "ArrowUp") {
                if (this.currentFocusIndex == 0) this.currentFocusIndex = null
                else this.currentFocusIndex--
            } else if (e.key === "ArrowDown") {
                if (this.currentFocusIndex == null) this.currentFocusIndex = 0
                else if (
                    this.currentFocusIndex <
                    this.termSuggestions.length +
                        this.jobSuggestions.length +
                        this.businessSuggestions.length -
                        1
                )
                    this.currentFocusIndex++
            }
        },
        onSearchButtonClick() {
            this.$oabalytics.trackEvent("search-btn-click", {
                query: this.searchQuery,
            })
            this.executeSearch(this.searchQuery)
            this.closeSearchMenu()
        },
    },
}
