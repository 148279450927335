
import Dropdown from "@/components/search/v2/header/filters/Dropdown"
import DropdownField from "@/components/search/v2/header/filters/DropdownField"
import ApprenticeshipTypesFilter from "@/components/search/v2/header/filters/ApprenticeshipTypesFilter"
import Button from "@/components/base/Button"
import Card from "@/components/base/card/Card"
import NumberInput from "@/components/base/input/NumberInput"
import Slider from "@/components/base/input/Slider"

import mappingService from "@/services/mapping.service"
import {
    DEFAULT_DISTANCE,
    SEARCH_DURATIONS,
    SEARCH_GRADUATIONS,
    SEARCH_RESPONSE_TIMES,
    SEARCH_START_YEARS,
} from "@/models/search"

import { mdiChevronDown, mdiClose } from "@mdi/js"
import { location, filters } from "@/assets/icons/js"
import { hasParentWithId } from "@/helpers/dom"

function numberOrNull(value) {
    if (!value) return null
    return Number(value)
}

export default {
    name: "SearchFilters",
    components: {
        Slider,
        Card,
        Button,
        Dropdown,
        DropdownField,
        NumberInput,
        ApprenticeshipTypesFilter,
    },
    emits: ["input", "change:search", "location-select"],
    props: {
        /** location name string */
        locationName: {
            type: String,
            default: "...",
        },
        /** search object */
        search: {
            type: Object,
            required: true,
        },
        /** For dropdown model */
        value: {
            type: Boolean,
            default: false,
        },
        /** Apply filter */
        delayedMode: {
            type: Boolean,
            default: false,
        },
    },
    icons: {
        mdiChevronDown,
        mdiClose,
        location,
        filters,
    },
    data: () => ({
        SEARCH_RESPONSE_TIMES,
        SEARCH_DURATIONS,
        SEARCH_GRADUATIONS,
        SEARCH_START_YEARS,
        selectedGraduationIndex: null,
        selectedDurationIndex: null,
        selectedStartYearIndex: null,
        lowerSalary: null,
        sliderDistance: DEFAULT_DISTANCE,
        sliderResponseTime: 0,
        // Changes done but not yet emitted
        isChanged: false,
    }),
    computed: {
        model: {
            get() {
                return this.value
            },
            set(value) {
                if (value) {
                    this.applyValuesFromSearch()
                }
                this.$emit("input", value)
            },
        },
        numberOfActiveFilters() {
            return this.search.numberOfActiveFilters
        },
        isChannel() {
            return !this.search?.location && !!this.search?.channel
        },
    },
    deactivated() {
        window.removeEventListener("popstate", this.onPopState)
    },
    mounted() {
        // Listen to back/forth browser buttons to reset search
        window.addEventListener("popstate", this.onPopState)
    },
    created() {
        this.applyValuesFromSearch()
    },
    methods: {
        //** MAPPING METHODS **//
        responseTimeMapping(value) {
            if (value === this.SEARCH_RESPONSE_TIMES.length) return "beliebig"
            return (
                mappingService.mapBusinessResponseTime(
                    this.SEARCH_RESPONSE_TIMES[value]
                ) + "h"
            )
        },
        //** UPDATE SEARCH OBJECT **//
        onDistance(distance) {
            this.search.setDistance(distance)
            this.onFiltersChange("distance", distance)
        },
        onGraduation(index) {
            let graduation = Object.keys(SEARCH_GRADUATIONS)[index]
            if (graduation === "undefined") graduation = undefined
            this.search.setGraduation(graduation)
            this.onFiltersChange("graduation", graduation)
        },
        onDuration(index) {
            let months = (index + 1) * 12
            if (index === SEARCH_DURATIONS.length - 1) months = undefined
            this.search.setDuration(months)
            this.onFiltersChange("duration", months)
        },
        onStartYear(index) {
            const year = SEARCH_START_YEARS[index]
            this.search.setStartYear(year)
            this.onFiltersChange("start-year", year)
        },
        onLowerSalary(salary) {
            if (!salary) salary = undefined
            this.search.setLowerSalary(salary)
            this.onFiltersChange("lower-salary", salary)
        },
        onResponseTime(time) {
            const value = this.SEARCH_RESPONSE_TIMES[time]
            this.search.setResponseTime(value)
            this.onFiltersChange("response-time", value)
        },
        onWithTraineeship(value) {
            this.search.setHasTraineeship(value)
            this.onFiltersChange("has-traineeship", value)
        },
        onWithDualStudy(value) {
            this.search.setHasDualStudy(value)
            this.onFiltersChange("has-dual-study", value)
        },
        onWithGraduationProgram(value) {
            this.search.setHasGraduationProgram(value)
            this.onFiltersChange("has-graduation-program", value)
        },
        onApprenticeshipTypeSelect(filter, value) {
            if (filter === "has-traineeship") this.onWithTraineeship(value)
            else if (filter === "has-dual-study") this.onWithDualStudy(value)
            else if (filter === "has-graduation-program")
                this.onWithGraduationProgram(value)
        },
        emitSearchChange() {
            this.$emit("change:search")
        },
        trackFilter(filter, value) {
            this.$oabalytics.trackEvent("search-filter", {
                filter,
                value,
            })
        },
        onFiltersChange(filter, value) {
            if (this.$breakpoint.smAndUp) {
                this.emitSearchChange()
            } else this.isChanged = true
            this.trackFilter(filter, value)
        },
        updateAllAndClose() {
            this.applyValuesFromSearch()
            this.emitSearchChange()
            this.isChanged = false
        },
        resetAll() {
            this.trackFilter("reset")
            this.search.resetQuery()
            this.applyValuesFromSearch()
            this.emitSearchChange()
        },
        applyValuesFromSearch() {
            if (!this.search) return
            this.sliderDistance = numberOrNull(this.search.distance)
            this.lowerSalary = numberOrNull(this.search.lowerSalary)
            this.selectedDurationIndex =
                numberOrNull(this.search.duration) / 12 - 1
            this.selectedGraduationIndex = Object.keys(
                SEARCH_GRADUATIONS
            ).findIndex((el) => el === this.search.graduation)
            this.selectedStartYearIndex = SEARCH_START_YEARS.findIndex(
                (el) => el === numberOrNull(this.search.startYear)
            )
            if (this.search.responseTime) {
                this.sliderResponseTime = this.SEARCH_RESPONSE_TIMES.findIndex(
                    (el) => el === this.search.responseTime
                )
            } else {
                this.sliderResponseTime = this.SEARCH_RESPONSE_TIMES.length
            }
        },
        resetInputs() {
            this.sliderDistance = DEFAULT_DISTANCE
            this.sliderResponseTime = this.SEARCH_RESPONSE_TIMES.length
            this.lowerSalary = null
            this.selectedDurationIndex = null
            this.selectedGraduationIndex = null
            this.selectedStartYearIndex = null
        },
        onOpenFilters() {
            this.model = true
            this.$oabalytics.trackEvent("search-filters-open")
        },
        onCloseFilters() {
            this.model = false
            if (this.delayedMode) {
                this.updateAllAndClose()
            }
        },
        async onPopState() {
            this.applyValuesFromSearch()
            await this.search.setParamsFromRoute(this.$route)
            await this.search.execute(true)
        },
        onClickOutside(e) {
            if (hasParentWithId(e.srcElement, "search-filters-button")) return
            this.model = false
        },
    },
}
