function hasParentWithId(node, parentId) {
    let current = node
    while (current) {
        if (current.id === parentId) {
            return true
        }
        if (current.nodeType === Node.DOCUMENT_NODE) {
            return false
        }
        current = current.parentNode
    }

    return false
}

export { hasParentWithId }
