
import { Search } from "@/models/search"
import SearchCategories from "@/components/search/v2/header/categories/SearchCategories"
import SearchFilters from "@/components/search/v2/header/filters/SearchFilters"
import SearchDropdown from "@/components/search/v2/header/SearchDropdown"
import LoginProfileButtons from "@/components/auth/LoginProfileButtons"

export default {
    name: "SearchHeader",
    components: {
        SearchDropdown,
        LoginProfileButtons,
        SearchFilters,
        SearchCategories,
    },
    props: {
        /**
         * search object
         */
        search: {
            type: Search,
            required: true,
            validator: () => true,
        },
        /**
         * search area (location/channel) name displayed on the page (e.g. Passau)
         */
        searchAreaName: {
            type: String,
            default: "",
        },
    },
    data: () => ({
        hideSearchInput: false,
        searchMenuModel: false,
        showLocationModal: false,
        numberOfActiveFilters: 0,
        filterModel: false,
        lastScroll: 0,
        disableOnScroll: false,
    }),
    computed: {
        tags() {
            if (this.search?.aggregations?.tags) {
                const arr = this.search.aggregations.tags.buckets
                const selectedIndex = arr.findIndex(
                    (item) => item.key === this.search.term
                )
                if (selectedIndex > -1) {
                    const selectedEl = arr.splice(selectedIndex, 1)
                    arr.splice(0, 0, ...selectedEl)
                    return arr.slice(0, 20) // render only 20 tags
                }
                return arr.slice(0, 20) // render only 20 tags
            }
            return []
        },
    },
    watch: {
        searchMenuModel(newValue) {
            if (newValue) {
                this.closeFilters()
            }
        },
        filterModel(newValue) {
            this.numberOfActiveFilters = Object.keys(newValue).length
        },
    },
    activated() {
        setTimeout(() => {
            this.disableOnScroll = false
        }, 300)
    },
    deactivated() {
        this.disableOnScroll = true
    },
    methods: {
        onSearch(query) {
            this.search.setTerm(query)
            this.search.setCategory()
            this.onSearchChange()
        },
        async onSearchChange() {
            this.$emit("change-search")
        },
        onTagClick(tag) {
            this.onSearch(tag.key)
            this.closeSearchMenu()
        },
        onLocationClick() {
            this.$emit("open-location-modal")
        },
        setCategory(category) {
            this.search.setCategory(category)
            this.onSearchChange()
        },
        closeFilters() {
            this.filterModel = false
            this.disableScroll()
        },
        reset() {
            this.$oabalytics.trackEvent("search-reset")
            // not executing search
            this.search.setCategory()
            this.search.setTerm()
            this.$refs.searchDropdown.clearSearchQuery()
            // executing search
            this.$refs.filters.resetAll()
        },
        closeSearchMenu() {
            this.searchMenuModel = false
        },
        disableScroll(timeout = 300) {
            this.disableOnScroll = true
            setTimeout(() => {
                this.disableOnScroll = false
            }, timeout)
        },
        onScroll() {
            if (this.disableOnScroll) return

            const currentScroll = window.scrollY
            const diff = currentScroll - this.lastScroll
            // Hide/show search input
            if (diff > 30 && currentScroll > 50) {
                this.hideSearchInput = true
            } else if (diff < -30) {
                this.hideSearchInput = false
            }

            if (diff <= 15 && diff > -5) {
                this.lastScroll = currentScroll
                return
            }

            let disable = false

            if (diff > 0 && this.searchMenuModel) {
                this.searchMenuModel = false
            }
            this.lastScroll = currentScroll
            if (disable) this.disableScroll()
        },
    },
}
