
export default {
    name: "FileInput",
    /** Emits file when selected, FileArray if "multiple" prop it set */
    emits: ["change"],
    props: {
        /** Accepted formats */
        accept: {
            type: [Array],
            default: () => ["image/*", "video/*"],
        },
        /** Disables input */
        disabled: {
            type: Boolean,
            default: false,
        },
        /** Allows multiple input files */
        multiple: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        acceptString() {
            return this.accept.join()
        },
    },
    methods: {
        open() {
            if (!this.disabled) {
                this.$refs.input?.click()
            }
        },
        onChange() {
            if (!this.$refs.input) return
            const files = this.$refs.input.files
            if (files) {
                if (!this.multiple) {
                    this.$emit("change", files[0])
                } else {
                    this.$emit("change", files)
                }
            }
            this.$refs.input.value = null
        },
    },
}
