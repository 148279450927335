
import BoxCheckboxFilter from "@/components/search/v2/header/filters/BoxCheckboxFilter"

import { icon_internship, icon_university, education } from "@/assets/icons/js"
export default {
    name: "ApprenticeshipTypesFilter",
    components: {
        BoxCheckboxFilter,
    },
    emits: ["select"],
    props: {
        search: {
            type: Object,
            required: true,
        },
    },
    icons: {
        icon_internship,
        icon_university,
        education,
    },
}
