
import searchService from "@/services/search.service"
import { Search } from "@/models/search"
import Swiper, { Mousewheel } from "swiper"
import SwiperWrapper from "@/components/swiper/SwiperWrapper"
import { mdiChevronLeft, mdiChevronRight } from "@mdi/js"
import { sortArrayWithSeed } from "@/helpers/utils"

export default {
    name: "SearchCategories",
    components: {
        SwiperWrapper,
        Button: () => import("@/components/base/Button"),
    },
    props: {
        /**
         * search object
         */
        search: {
            type: Search,
            required: true,
            validator: () => true,
        },
        /**
         * to display left & right scroll buttons
         */
        enableButtons: {
            type: Boolean,
            default: false,
        },
    },
    data: () => ({
        categories: [],
        loadingCategories: false,
        swiper: null,
        selectedCategory: null,
    }),
    computed: {
        loading() {
            return (
                this.loadingCategories ||
                !this.search ||
                !this.search.aggregations ||
                Object.keys(this.search.aggregations).length === 0
            )
        },
        minJobsInCategory() {
            const isEmptyTerm =
                !this.search.term || this.search.term.length === 0
            return isEmptyTerm ? 3 : 0
        },
        swiperOptions() {
            return {
                slidesPerView: "auto",
                centerInsufficientSlides: true,
                maxBackfaceHiddenSlides: 30,
                roundLengths: true,
                initialSlide: Math.max(
                    0,
                    this.filteredCategories.findIndex(
                        ({ identifier }) => identifier === this.selectedCategory
                    ) - 1
                ),
                modules: [Mousewheel],
                mousewheel: true,
                passiveListeners: true,
                height: 48,
            }
        },
        displayedCategories() {
            if (this.search?.aggregations?.categories) {
                return this.search.aggregations.categories.buckets
                    .filter(
                        ({ doc_count }) => doc_count > this.minJobsInCategory
                    )
                    .map(({ key }) => key)
            }
            return []
        },
        filteredCategories() {
            const seed = new Date().getDay()
            return sortArrayWithSeed(
                this.categories.filter(({ identifier }) =>
                    this.displayedCategories.includes(identifier)
                ),
                seed
            )
        },
        swipeOffsetOnClick() {
            if (this.$breakpoint.sm) return 3
            if (this.$breakpoint.md) return 5
            if (this.$breakpoint.lg) return 7
            if (this.$breakpoint.xl) return 10
            return 2
        },
    },
    watch: {
        search: {
            deep: true,
            handler: function (value) {
                this.setCategory(value.category)
            },
        },
    },
    icons: {
        mdiChevronLeft,
        mdiChevronRight,
    },
    async created() {
        this.loadingCategories = true
        this.categories = await searchService.getJobCategories()
        this.selectedCategory = this.search?.category
        this.loadingCategories = false
    },
    methods: {
        selectCategory(category) {
            if (this.selectedCategory === category) {
                this.setCategory(null)
                this.$oabalytics.trackEvent("search-category", {
                    category: null,
                })
                this.$emit("select", undefined)
            } else {
                this.setCategory(category)
                this.$oabalytics.trackEvent("search-category", { category })
                this.$emit("select", category)
            }
        },
        setCategory(category) {
            this.selectedCategory = category
        },
        onSwiperMounted() {
            if (this.$refs.swiperSearchCategories?.$refs?.swiper)
                this.swiper = new Swiper(
                    this.$refs.swiperSearchCategories.$refs.swiper,
                    this.swiperOptions
                )
        },
        isSelected(category) {
            return this.selectedCategory && category === this.selectedCategory
        },
        onClickLeft() {
            this.swiper.slideTo(
                this.swiper.activeIndex - this.swipeOffsetOnClick
            )
        },
        onClickRight() {
            this.swiper.slideTo(
                this.swiper.activeIndex + this.swipeOffsetOnClick
            )
        },
    },
}
