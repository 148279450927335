
export default {
    name: "JobContainer",
    props: {
        mobile: {
            type: Boolean,
            default: false,
        },
        fullwidth: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        classes() {
            return {
                "job-container": !this.fullwidth,
                "full-width": this.fullwidth,
                "job-container--mobile": this.mobile,
            }
        },
    },
}
