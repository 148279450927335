
import Button from "@/components/base/Button"
import Dropdown from "@/components/search/v2/header/filters/Dropdown"
import { mdiChevronDown } from "@mdi/js"

export default {
    name: "DropdownField",
    components: { Dropdown, Button },
    emits: ["input"],
    model: {
        prop: "value",
        event: "input",
    },
    props: {
        /** Array of possible options */
        items: {
            type: Array,
            default: () => [],
        },
        /** key of chosen element */
        value: {
            type: Number,
            default: null,
        },
        /** Max width of the text */
        maxWidth: {
            type: Number,
            default: 100,
            validator: (val) => val >= 0,
        },
        defaultValue: {
            type: String,
            default: "beliebig",
        },
        hideDefault: {
            type: Boolean,
            default: false,
        },
    },
    icons: {
        mdiChevronDown,
    },
    data: function () {
        return {
            id: "dropdown-field-" + this._uid,
        }
    },
    computed: {
        model: {
            get() {
                return this.value
            },
            set(value) {
                if (value === this.items.length) value = null // default value
                this.$emit("input", value)
            },
        },
        labelStyles() {
            return {
                "max-width": `${this.maxWidth}px`,
            }
        },
        buttonText() {
            return this.items[this.value] || this.defaultValue
        },
    },
}
