import { render, staticRenderFns } from "./BoxCheckbox.vue?vue&type=template&id=5567bba6&scoped=true&"
import script from "./BoxCheckbox.vue?vue&type=script&lang=js&"
export * from "./BoxCheckbox.vue?vue&type=script&lang=js&"
import style0 from "./BoxCheckbox.vue?vue&type=style&index=0&id=5567bba6&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5567bba6",
  null
  
)

export default component.exports