
import "swiper/swiper.scss"
import "swiper/modules/navigation/navigation"
import { mdiChevronRight, mdiChevronLeft } from "@mdi/js"

export default {
    name: "SwiperWrapper",
    props: {
        fullWidth: {
            type: Boolean,
            default: false,
        },
        swiperId: {
            type: String,
            required: true,
        },
        showButtons: {
            type: Boolean,
            default: true,
        },
    },
    icons: {
        mdiChevronLeft,
        mdiChevronRight,
    },
    computed: {
        swiperRef() {
            return "swiper_" + this.swiperId
        },
        swiperClasses() {
            return { "full-layout-width": this.fullWidth }
        },
    },
}
