
import BusinessLogo from "@/components/business/BusinessLogo"
import themes from "@/mixins/themes"
import { routeToBusiness } from "@/helpers/routing"
import { getApprenticeshipTypeName } from "@/helpers/job"
import { mdiMapMarker } from "@mdi/js"
import { APPRENTICESHIP, TRAINEESHIP, JOB } from "@/enums/jobtypes"
import { icon_university } from "@/assets/icons/js"

export default {
    name: "JobPageOverview",
    components: {
        JobPageTags: () => import("@/components/job/jobpage/JobPageTags"),
        BusinessLogo,
    },
    mixins: [themes],
    props: {
        /** Job object */
        job: {
            type: Object,
            required: true,
        },
        jobType: {
            type: String,
            default: null,
        },
    },
    icons: {
        icon_university,
        mdiMapMarker,
    },
    computed: {
        jobTypeName() {
            if (this.jobType) return this.jobType
            switch (this.job.type_id) {
                case APPRENTICESHIP: {
                    const apprenticeshipTypeName = getApprenticeshipTypeName(
                        this.job.apprenticeship
                    )
                    if (this.job.traineeship_data?.is_active) {
                        return `${apprenticeshipTypeName} / Praktikum`
                    }
                    return apprenticeshipTypeName
                }
                case TRAINEESHIP:
                    return "Praktikum"
                case JOB:
                    return "Job"
                default:
                    console.warn("Error: unrecognizable job type")
                    return ""
            }
        },
        routeToBusiness() {
            return routeToBusiness(this.job.business)
        },
        address() {
            const address =
                this.job.business_site?.address ??
                this.job.business.primary_address
            if (!address) return null
            return `${address.street}, ${address.city}`
        },
        institutionStyles() {
            return {
                "border-color": this.oaTheme.backgroundPrimary.text.color,
                ...this.oaTheme.backgroundPrimary.text,
            }
        },
        jobInstitutions() {
            return this.job.apprenticeship?.institutions ?? []
        },
    },
}
