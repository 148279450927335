
export default {
    name: "TextField",
    props: {
        inset: {
            type: Boolean,
            default: false,
        },
        small: {
            type: Boolean,
            default: false,
        },
    },
}
