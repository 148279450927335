
import {
    defineComponent,
    useFetch,
    ref,
    useRoute,
} from "@nuxtjs/composition-api"
import SearchPage from "@/components/search/v2/SearchPage"
import { Search } from "@/models/search"
import listsService from "@/services/lists.service"

export default defineComponent({
    name: "AusbildungLocationSlug",
    components: {
        SearchPage,
    },
    layout: "modern",
    middleware: ["no-business", "no-school"],
    meta: {
        footer: {
            showMobileAuth: true,
        },
        toolbar: {
            hideSearch: true,
        },
    },
    validate({ params }) {
        return !!params.locationSlug
    },
    beforeRouteLeave(to, _, next) {
        if (to.name === "betriebe-slug-jobs-jobSlug" && !!this.search?.data) {
            const job = this.search.data.find(
                (item) => item.slug === to.params.jobSlug
            )
            this.$store.commit("job/SET_DISPLAYED_MINIMAL_JOB", job)
        }
        next()
    },
    computed: {
        customItems() {
            if (this.search.channel !== "oabat") return []
            const component = this.$livestream.isAdvertisementActive
                ? () => import("@/components/livestream/LivestreamCard.vue")
                : () => import("@/components/trainee/LikeCard")
            let items = []
            for (let i = 0; i < this.lists?.length; i++) {
                const list = this.lists[i]
                items.push({
                    id: `inline-card-${i + 1}`, // Used as v-for-key
                    slug: `inline-card-${i + 1}`, // Used for oabalytics
                    component: () =>
                        import("@/components/search/v2/lists/ListCard"),
                    boundData: {
                        item: list,
                        imageFormat: "job@2",
                        minHeight: "400px",
                        location: this.search.location,
                    },
                })
            }
            items.push({
                id: `inline-card-` + (items.length + 1), // Used as v-for-key
                slug: `inline-card-` + (items.length + 1), // Used for oabalytics
                component,
            })
            return items
        },
    },
    setup() {
        const route = useRoute()
        const lists = ref()
        const search = ref(
            new Search({
                type: "apprenticeship",
                hasTraineeship: route.value.query.praktikum !== undefined,
            })
        )
        useFetch(async () => {
            if (search.value.data.length > 0) return
            await search.value.setParamsFromRoute(route.value)
            await search.value.execute()
            lists.value = await listsService.getJobLists()
        })

        return { search, lists }
    },
})
